import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/members',
    name: 'members',
    component: () => import(/* webpackChunkName: "about" */ '../views/Members.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "about" */ '../views/News.vue')
  },
  {
    path: '/students',
    name: 'students',
    component: () => import(/* webpackChunkName: "about" */ '../views/Students.vue')
  },
  {
    path: '/partners',
    name: 'partners',
    component: () => import(/* webpackChunkName: "about" */ '../views/Partners.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
  },
  {
    path: '/ourservices',
    name: 'ourservices',
    component: () => import(/* webpackChunkName: "about" */ '../views/onepage/OurServices.vue')
  },
  {
    path: '/financial',
    name: 'financial',
    component: () => import(/* webpackChunkName: "about" */ '../views/onepage/FinancialAssitance.vue')
  },
  {
    path: '/workjp',
    name: 'workjp',
    component: () => import(/* webpackChunkName: "about" */ '../views/onepage/WorkingInJapan.vue')
  },
  {
    path: '/digitaleco',
    name: 'digitaleco',
    component: () => import(/* webpackChunkName: "about" */ '../views/onepage/DigitalEcosystem.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router
